<template>
  <div class="page">
    <Login ref="login" :callback="init" />
    <Navbar title="留学明细" :callback="back" type="SMP" />
    <van-swipe class="top-img" :autoplay="3000" indicator-color="white">
      <van-swipe-item
        ><van-image
          radius="3"
          class="img"
          v-if="code === '1'"
          :src="require('../../../../assets/images/labor/china.jpg')"
        ></van-image>
        <van-image
          radius="3"
          class="img"
          v-if="code === '2'"
          :src="require('../../../../assets/images/labor/singapore.jpg')"
        ></van-image>
        <van-image
          radius="3"
          class="img"
          v-if="code === '3'"
          :src="require('../../../../assets/images/labor/rb.jpg')"
        ></van-image>
        <van-image
          radius="3"
          class="img"
          v-if="code === '4'"
          :src="require('../../../../assets/images/labor/adly.jpg')"
        ></van-image>
        <van-image
          radius="3"
          class="img"
          v-if="code === '5'"
          :src="require('../../../../assets/images/labor/208_nzl.png')"
        ></van-image
      ></van-swipe-item>
    </van-swipe>
    <div class="simple">
      <van-row class="item bbd">
        <van-col span="24" class="name text-short">
          {{ product.name }}
        </van-col>
      </van-row>
      <van-row class="item bbd">
        <van-col span="8"
          ><van-tag mark :color="COLOR_M">薪资福利：</van-tag></van-col
        >
        <van-col span="16">
          <div v-for="label in product.labels" :key="label.code" class="labels">
            <van-tag class="label" v-if="label.type === 'WFE'">{{
              label.name
            }}</van-tag>
          </div>
        </van-col>
      </van-row>
      <van-row class="item">
        <van-col span="8"
          ><van-tag mark :color="COLOR_M">岗位类型：</van-tag></van-col
        >
        <van-col span="16">
          <div v-for="label in product.labels" :key="label.code" class="labels">
            <van-tag class="label" v-if="label.type === 'JOB'">{{
              label.name
            }}</van-tag>
          </div>
        </van-col>
      </van-row>
      <div class="hr"></div>
      <van-row class="service">
        <van-col span="3"
          ><svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-service"></use></svg
        ></van-col>
        <van-col span="15" class="text"
          ><span class="color-s2">专业服务团队，线下1V1服务</span></van-col
        >
        <van-col span="6" class="button">
          <van-button
            class="btn"
            icon="gold-coin-o"
            :color="COLOR_M"
            size="mini"
            @click="createOrder()"
            >咨询申请</van-button
          >
        </van-col>
      </van-row>
    </div>
    <van-row @click="home()">
      <van-col span="24">
        <van-image
          class="focus-img"
          :src="require('../../../../assets/images/recruit/wp-focus.jpg')"
        ></van-image>
      </van-col>
    </van-row>
    <div class="detail">
      <van-tabs :active="active" :color="COLOR_M" border="true">
        <van-tab title="详情描述">
          <div class="content" v-html="product.description"></div>
        </van-tab>
      </van-tabs>
    </div>
    <van-popup
      :show="shareShow"
      @click-overlay="shareShow = false"
      @click="shareShow = false"
      style="background-color: transparent; width: 100%; height: 100%"
    >
      <img
        class="shareImg"
        :src="require('../../../../assets/images/online/share-top.png')"
      />
    </van-popup>
    <Share ref="share" />
    <LogVisit ref="logVisit" module-type="DGE" module="DGE_DETAIL_INDEX" />
    <Loading :show="loadingShow" />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
